import ConfettiGenerator from 'confetti-js'
import { Link, navigate } from 'gatsby'
import { useAdminAcceptInvite } from 'medusa-react'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { decodeToken } from 'react-jwt'
import Button from '../components/fundamentals/button'
import LongArrowRightIcon from '../components/fundamentals/icons/long-arrow-right-icon'
import MedusaIcon from '../components/fundamentals/icons/medusa-icon'
import SigninInput from '../components/molecules/input-signin'
import SEO from '../components/seo'
import LoginLayout from '../components/templates/login-layout'
import useNotification from '../hooks/use-notification'
import { getErrorMessage } from '../utils/error-messages'
import { NordfraIcon } from '../components/fundamentals/nordfra-icon'

type formValues = {
    password: string
    repeat_password: string
    first_name: string
    last_name: string
}

const InvitePage = ({ location }) => {
    const parsed = qs.parse(location.search.substring(1))
    const [signUp, setSignUp] = useState(false)

    let token: Object | null = null
    if (parsed?.token) {
        try {
            token = decodeToken(parsed.token as string)
        } catch (e) {
            token = null
        }
    }

    const [passwordMismatch, setPasswordMismatch] = useState(false)
    const [ready, setReady] = useState(false)

    const { register, handleSubmit, formState } = useForm<formValues>({
        defaultValues: {
            first_name: '',
            last_name: '',
            password: '',
            repeat_password: '',
        },
    })

    const accept = useAdminAcceptInvite()
    const notification = useNotification()

    const handleAcceptInvite = (data: formValues) => {
        setPasswordMismatch(false)

        if (data.password !== data.repeat_password) {
            setPasswordMismatch(true)
            return
        }

        accept.mutate(
            {
                token: parsed.token as string,
                user: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    password: data.password,
                },
            },
            {
                onSuccess: () => {
                    navigate('/login')
                },
                onError: (err) => {
                    notification('Error', getErrorMessage(err), 'error')
                },
            }
        )
    }

    useEffect(() => {
        if (
            formState.dirtyFields.password &&
            formState.dirtyFields.repeat_password &&
            formState.dirtyFields.first_name &&
            formState.dirtyFields.last_name
        ) {
            setReady(true)
        } else {
            setReady(false)
        }
    }, [formState])

    return (
        <>
            {signUp ? (
                <LoginLayout>
                    <SEO title="Create Account" />
                    <div className="flex h-full w-full items-center justify-center">
                        <div className="flex min-h-[600px] bg-grey-0 rounded-rounded justify-center">
                            <form
                                className="flex flex-col py-12 w-full px-[120px] items-center"
                                onSubmit={handleSubmit(handleAcceptInvite)}
                            >
                                <NordfraIcon />
                                {!token ? (
                                    <div className="h-full flex flex-col gap-y-2 text-center items-center justify-center">
                                        <span className="inter-large-semibold text-grey-90">
                                            Leinkjan er ógildig
                                        </span>
                                        <span className="inter-base-regular mt-2 text-grey-50">
                                            Set teg í samband við umsitaran fyri
                                            at fáa eina nýggja leinkju
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <span className="inter-2xlarge-semibold mt-4 text-grey-90">
                                            Vælkomin
                                        </span>
                                        <span className="inter-base-regular text-grey-50 mt-2 mb-large">
                                            Stovna ein brúkara niðanfyri
                                        </span>
                                        <SigninInput
                                            placeholder="Fornavn"
                                            name="first_name"
                                            ref={register({ required: true })}
                                            autoComplete="given-name"
                                        />
                                        <SigninInput
                                            placeholder="Eftirnavn"
                                            name="last_name"
                                            ref={register({ required: true })}
                                            autoComplete="family-name"
                                        />
                                        <SigninInput
                                            placeholder="Loyniorð"
                                            type={'password'}
                                            name="password"
                                            ref={register({ required: true })}
                                            autoComplete="new-password"
                                        />
                                        <SigninInput
                                            placeholder="Endurtak loyniorð"
                                            type={'password'}
                                            name="repeat_password"
                                            ref={register({ required: true })}
                                            autoComplete="new-password"
                                        />
                                        {passwordMismatch && (
                                            <span className="text-rose-50 w-full mt-2 inter-small-regular">
                                                The two passwords are not the
                                                same
                                            </span>
                                        )}
                                        <Button
                                            variant="primary"
                                            size="large"
                                            type="submit"
                                            className="w-full mt-base"
                                            loading={formState.isSubmitting}
                                            disabled={!ready}
                                        >
                                            Stovna brúkara
                                        </Button>
                                        <Link
                                            to="/login"
                                            className="inter-small-regular text-grey-50 mt-large"
                                        >
                                            Already signed up? Log in
                                        </Link>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </LoginLayout>
            ) : (
                <div className="bg-white h-screen w-full overflow-hidden">
                    <div className="z-10 flex-grow flex flex-col items-center justify-center h-full absolute inset-0 max-w-[1080px] mx-auto">
                        <NordfraIcon />
                        <div className="flex flex-col items-center max-w-3xl text-center">
                            <p className="text-nordfra-500 mt-8">
                                Tú hevur móttikið eina innbjóðing til at umsita
                                Norðfra nethandilin
                            </p>
                        </div>
                        <div className="mt-8">
                            <Button
                                size="large"
                                variant="primary"
                                className="w-[280px]"
                                onClick={() => setSignUp(true)}
                            >
                                Stovna brúkara
                                <LongArrowRightIcon
                                    size={20}
                                    className="pt-1"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default InvitePage
